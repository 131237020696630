import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-5 text-left md-font"},[_vm._v("Business Show Case")]),_c(VForm,{ref:"form",staticClass:"companyProfileForm w-100",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"w-100",attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{staticClass:"mb-10",attrs:{"cols":"12","md":"12"}},[_c('CustomInput',{attrs:{"type":"avatar","rules":[],"label":"Company Logo*","name":"company_logo_file","config":{
            accept: 'image/*'
          },"showFileName":false,"clearMediaConfirmation":""},on:{"clearFile":_vm.clearFile,"input":_vm.input},model:{value:(_vm.formValues.company_logo_file),callback:function ($$v) {_vm.$set(_vm.formValues, "company_logo_file", $$v)},expression:"formValues.company_logo_file"}})],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-left mb-5"},[_vm._v("Company Info")]),_c('div',[_c('CustomInput',{attrs:{"name":"company_write_up","type":"textarea","label":"Company Write Up","rules":[],"dark":"","outlined":""},on:{"input":_vm.input},model:{value:(_vm.formValues.company_write_up),callback:function ($$v) {_vm.$set(_vm.formValues, "company_write_up", $$v)},expression:"formValues.company_write_up"}})],1)]),_c(VCol,{staticClass:"mb-4",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-left mb-5 font-weight-bold"},[_vm._v(" Upload videos and photos to increase your social engagement and being noticed. ")]),_c('div',[_c('CustomInput',{staticClass:"text-left",attrs:{"name":"company_showreel_video_file","rules":[],"type":"file","config":{
              accept: 'video/*'
            },"label":"Video (Optional)","dark":"","outlined":"","clearMediaConfirmation":""},on:{"clearFile":_vm.clearFile,"input":function($event){return _vm.checkFileSize($event, 'company_showreel_video_file')}},model:{value:(_vm.formValues.company_showreel_video_file),callback:function ($$v) {_vm.$set(_vm.formValues, "company_showreel_video_file", $$v)},expression:"formValues.company_showreel_video_file"}})],1)]),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-left"},[_vm._v("Photo")]),_c('div',{staticClass:"d-flex",staticStyle:{"overflow":"auto"}},[_vm._l((_vm.formValues.company_showreel_image_file),function(photo,index){return _c('div',{key:index,staticClass:"me-2"},[(photo)?_c('CustomInput',{staticClass:"text-left",attrs:{"name":"company_showreel_image_file","rules":[],"type":"file","config":{
                accept: 'image/*'
              },"showFileName":false,"clearMediaConfirmation":"","dark":"","outlined":""},on:{"clearFile":function($event){return _vm.clearGalleryFile('company_showreel_image_file', photo, index)},"input":_vm.input},model:{value:(_vm.formValues.company_showreel_image_file[index]),callback:function ($$v) {_vm.$set(_vm.formValues.company_showreel_image_file, index, $$v)},expression:"formValues.company_showreel_image_file[index]"}}):_vm._e()],1)}),(_vm.formValues.company_showreel_image_file.length < 5)?_c('div',[_c('CustomInput',{staticClass:"text-left",attrs:{"name":"company_showreel_image_file","rules":[],"type":"file","config":{
                accept: 'image/*'
              },"dark":"","outlined":""},on:{"input":_vm.input},model:{value:(
                _vm.formValues.company_showreel_image_file[
                  _vm.formValues.company_showreel_image_file.length
                ]
              ),callback:function ($$v) {_vm.$set(_vm.formValues.company_showreel_image_file, 
                  _vm.formValues.company_showreel_image_file.length
                , $$v)},expression:"\n                formValues.company_showreel_image_file[\n                  formValues.company_showreel_image_file.length\n                ]\n              "}})],1):_vm._e()],2)]),_c(VCol,{staticClass:"my-10",attrs:{"cols":"12","md":"12"}},[_c('CustomInput',{attrs:{"type":"textarea","name":"list_projects","rules":[],"label":"Notable Project","dark":"","outlined":""},on:{"input":_vm.input},model:{value:(_vm.formValues.list_projects),callback:function ($$v) {_vm.$set(_vm.formValues, "list_projects", $$v)},expression:"formValues.list_projects"}})],1)],1)],1),_c(VRow,{staticClass:"text-center mt-12 pb-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VBtn,{staticClass:"btn1 mb-3",attrs:{"disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Save & Next ")])],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"underlineBtn mb-3",on:{"click":function($event){return _vm.$emit('doItLater')}}},[_vm._v(" Do it later ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="mb-5 text-left md-font">
      {{ agencyRole ? 'Personal Info' : 'Business Basic Info' }}
    </div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="businessInfoForm w-100"
    >
      <v-row no-gutters class="w-100">
        <v-col
          cols="12"
          md="6"
          v-for="(form, index) in businessInfoFormFields"
          :key="index"
        >
          <div class="px-2">
            <AppFormField
              v-if="form.field !== 'other'"
              v-model="formValues[form.key]"
              :name="`form_${form.key}`"
              :label="$t(`label.${form.label}`)"
              :rules="form.rules"
              :type="form.formType"
              :items="form.options"
              :multiple="form.multiple"
              :disabled="form.disabled"
              itemColor="background"
              :color="form.color || 'surface'"
              outlined
              dark
              @input="input"
            />
            <AppFormField
              v-if="
                form.field == 'other' &&
                form.key == 'description_other' &&
                isOtherDescription
              "
              v-model="formValues[form.key]"
              :name="`form_${form.key}`"
              :label="$t(`label.${form.label}`)"
              :rules="form.rules"
              :type="form.formType"
              itemColor="background"
              :color="form.color || 'surface'"
              dark
              @input="input"
            />
            <vue-tel-input-vuetify
              v-if="form.field === 'other' && form.key === 'mobile'"
              :preferredCountries="['SG', 'MY']"
              :defaultCountry="
                $get(formValues, 'mobileDialCodeCountry.abbr', 'MY')
              "
              class="telInput"
              v-model="formValues.mobile"
              :label="$t('label.mobileNo')"
              mode=""
              required
              outlined
              dark
              input
              disabled
              @input="onNumberChange"
              @country-changed="updateCountry"
            ></vue-tel-input-vuetify>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters class="text-center mt-12 pb-2">
      <v-col cols="12" md="12">
        <v-btn class="btn1 mb-3" @click="save" :disabled="!valid">
          Save & Next
        </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <div class="underlineBtn mb-3" @click="$emit('doItLater')">
          Do it later
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { formValidationHelper, uiHelper } from '@/utils';
  import { PROFILE } from '@/api';
  import { listService, i18nService } from '@/services';
  import { LOCAL } from '@/constants';
  import constantsCountry from '@/filters/constants-country.filter';

  export default {
    name: 'BusinessInfo',
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      formValues: {
        designation: null,
        description: null,
        description_other: null,
        talents_no: null,
        email: null,
        country_id: null,
        mobile_dial_code: '+65',
        mobile: null,
        name: null
      },
      valid: false,
      currentLanguage: i18nService.getActiveLanguage(),
      formValidation: formValidationHelper
    }),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      agencyRole() {
        return this.userInfo?.role == 'agency';
      },
      businessInfoFormFields() {
        let formFields = [
          {
            key: 'name',
            label: 'agencyName',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'designation',
            label: 'myDesignationIs',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'description',
            label: 'weAreAAn',
            formType: 'select',
            options: listService.getDynamicList(
              this.userInfo?.role == 'agency'
                ? this.constantsData.agency_description
                : this.constantsData.production_house_description
            ),
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'description_other',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'country_id',
            label: 'countryOfResidence',
            formType: 'select',
            options: listService.getCountryList(
              this.constantsData.all_country,
              this.currentLanguage
            ),
            rules: formValidationHelper.requiredRules(),
            multiple: true
          },
          {
            key: 'mobile',
            label: 'mobileNo',
            field: 'other'
          }
        ];
        formFields.splice(this.agencyRole ? 5 : 4, 0, {
          key: 'email',
          label: 'email',
          rules:
            this.userInfo?.register_method == 'email'
              ? formValidationHelper
                  .emailRules()
                  .concat(formValidationHelper.requiredRules())
              : formValidationHelper.requiredRules()
        });
        if (this.agencyRole) {
          formFields.splice(4, 0, {
            key: 'talents_no',
            label: 'noOfTalents',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.talents_no),
            rules: formValidationHelper.requiredRules()
          });
        }

        return formFields;
      },
      phoneCodes() {
        return listService.getPhoneCodeList(this.constantsData.country);
      },
      isOtherDescription() {
        const otherValueKey = this.agencyRole
          ? this.constantsData.agency_description.find((x) => x.key === 'OTHER')
              .value
          : this.constantsData.production_house_description.find(
              (x) => x.key === 'OTHER'
            ).value;
        return this.formValues.description == otherValueKey;
      }
    },
    watch: {
      isOtherDescription(val) {
        if (!val) this.formValues.description_other = null;
      }
    },
    methods: {
      onNumberChange(v, v2) {
        this.formValues.mobile = v2.number.significant;
        this.$emit('input', this.formValues);
      },
      updateCountry(event) {
        this.formValues.mobile_dial_code = '+' + event.dialCode;
        this.formValues.mobileDialCodeCountry = constantsCountry(
          this.formValues.mobile_dial_code,
          'getConstantsByPhoneCode'
        );
        this.$emit('input', this.formValues);
      },
      async save() {
        this.$startLoading();

        try {
          const formValidate = this.$refs['form'].validate();
          this.$closeModal();
          if (formValidate === true) {
            await PROFILE.updateBusinessInfo(this.formValues);
            this.next();
          }
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      next() {
        this.$emit('next', this.formValues);
      },
      input() {
        this.$emit('input', this.formValues);
      }
    },
    mounted() {
      const descriptionVal =
        this.userInfo.role == 'agency'
          ? this.constantsData.agency_description.find(
              (x) => x.description === this.userInfo?.description
            )?.value
          : this.constantsData.production_house_description.find(
              (x) => x.description === this.userInfo?.description
            )?.value;
      const talentsNoVal = this.constantsData.talents_no.find(
        (x) => x.description === this.userInfo?.talents_no
      )?.value;

      this.formValues = {
        name: this.userInfo.display_name,
        designation: this.userInfo.designation,
        description: descriptionVal,
        description_other: this.userInfo.description_other,
        talents_no: talentsNoVal,
        email: this.userInfo.email,
        country_id: this.userInfo.country_id,
        mobile_dial_code: this.userInfo.mobile_dial_code,
        mobile: this.userInfo.mobile,
        mobileDialCodeCountry: constantsCountry(
          this.userInfo.mobile_dial_code || '+60',
          'getConstantsByPhoneCode'
        )
      };
    }
  };
</script>

<style></style>

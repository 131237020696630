<template>
  <div>
    <div
      class="headerStep"
      :class="currentStep == steps.length - 1 ? 'active' : ''"
    >
      <div class="container">
        <div class="headerScroll d-flex" v-if="!isCreate">
          <div
            v-for="(step, i) in steps"
            :key="i"
            @click="currentStep = i"
            :class="'headerItem ' + (i <= currentStep ? 'active' : '')"
          >
            Step {{ i + 1 }} - {{ step }}
          </div>
        </div>
        <div class="headerScroll d-flex" v-else>
          <div
            v-for="(step, i) in steps"
            :key="i"
            @click="currentStep = i"
            :class="'headerItem ' + (i <= currentStep ? 'active' : '')"
          >
            Step {{ i + 1 }} - {{ step }}
          </div>
        </div>
      </div>
    </div>

    <div class="container wrapper">
      <div v-if="!isLive">
        <h1 class="pageHeader mt-12 text-uppercase">
          {{ freelancerRole ? 'Profile Info' : 'Company Profile' }}
        </h1>
        <div class="w-100 text-center py-12">
          <UserInfo
            :userInfo="me"
            :isCreate="isCreate"
            v-if="currentStep == 0"
            @next="next"
            @doItLater="currentStep = 1"
            @input="formValues = { ...formValues, ...$event }"
          />

          <BioInfo
            :userInfo="me"
            :isCreate="isCreate"
            freelancerRole
            v-if="currentStep == 1 && freelancerRole"
            @next="next"
            @doItLater="currentStep = 2"
            @input="formValues = { ...formValues, ...$event }"
          />
          <BusinessInfo
            :userInfo="me"
            :isCreate="isCreate"
            v-if="currentStep == 1 && !freelancerRole"
            @next="next"
            @doItLater="currentStep = 2"
            @input="formValues = { ...formValues, ...$event }"
          />
          <CompanyProfile
            :userInfo="me"
            :isCreate="isCreate"
            v-if="currentStep == 2 && !freelancerRole"
            @next="next"
            @doItLater="currentStep = 3"
            @input="formValues = { ...formValues, ...$event }"
          />
          <BioIntroInfo
            :userInfo="me"
            :isCreate="isCreate"
            freelancerRole
            v-if="currentStep == 2 && freelancerRole"
            @next="next"
            @doItLater="currentStep = 3"
            @input="formValues = { ...formValues, ...$event }"
          />
          <SocialPlatform
            :userInfo="me"
            :isCreate="isCreate"
            v-if="currentStep == 3"
            @input="formValues = { ...formValues, ...$event }"
            @next="createProfile()"
          />
        </div>
      </div>
      <div class="w-100 text-center py-12" v-if="isLive">
        <h1 class="pageHeader mx-12 my-12 px-12 text-left" style="color: white">
          <div>Your company profile is completed.</div>
          <div class="pb-12">It is now live!</div>
        </h1>
        <div class="d-flex justify-center pt-12">
          <v-btn class="btn1" @click="$router.push('/profile')">
            See Profile
          </v-btn>
        </div>
        <div class="d-flex justify-center pt-7 pb-10">
          <div class="underlineBtn" @click="$router.push('/')">
            Back to home
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserInfo from '@/components/profile/UserInfo.vue';
  import BioInfo from '@/components/profile/BioInfo.vue';
  import BusinessInfo from '@/components/profile/BusinessInfo.vue';
  import CompanyProfile from '@/components/profile/CompanyProfile.vue';
  import SocialPlatform from '@/components/profile/SocialPlatform.vue';
  import BioIntroInfo from '@/components/profile/BioIntroInfo.vue';
  import { AUTH_ME } from '@/store/auth.module';
  import { PROFILE } from '@/api';
  import { LOCAL } from '@/constants';
  import { uiHelper } from '@/utils';
  import { mapGetters } from 'vuex';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'CompanyInfo',
    components: {
      UserInfo,
      BusinessInfo,
      CompanyProfile,
      SocialPlatform,
      BioInfo,
      BioIntroInfo
    },
    data: () => ({
      currentStep: -1,
      steps: ['User Info', 'Business Info', 'Company Info', 'Social Platform'],
      isLive: false,
      isCreate: false,
      formValues: {},
      freelancerMedia: [
        {
          key: 'cv_file',
          mediaCollectionsName: 'cv'
        },
        {
          key: 'intro_video_files',
          mediaCollectionsName: 'intro_video'
        },
        {
          key: 'gallery_file',
          mediaCollectionsName: 'gallery'
        },
        {
          key: 'headshot_file',
          mediaCollectionsName: 'headshot'
        }
      ],
      me: {}
    }),
    computed: {
      freelancerRole() {
        return this.me?.role == 'freelancer';
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      ...mapGetters({
        mediaCollections: 'constants/mediaCollections',
        authUser: ['auth/me']
      })
    },
    watch: {
      async currentStep(val) {
        this.$scrollToTop();
        await this.getMe();
        if (val != -1) this.isLive = false;
      }
    },
    methods: {
      async getMe() {
        await this.$store.dispatch(AUTH_ME);
        this.$nextTick(() => {
          this.me = {
            ...this.authUser
          };
        });
      },

      next(e) {
        if (this.isCreate) {
          this.formValues = {
            ...this.formValues,
            ...e
          };
        }
        this.currentStep += 1;
      },

      async createProfile() {
        if (!this.isCreate) {
          this.getMe();
          this.navigateToDisplayInfo();
        } else {
          this.$startLoading();

          try {
            const profile = await PROFILE.createProfile(
              this.formValues,
              this.me.role
            );
            const profileId = profile.data.profile.profile.profile_id;
            await this.saveMedias(profileId);
            this.$closeModal();
            await this.$store.dispatch(AUTH_ME);
            this.navigateToDisplayInfo();
          } catch (e) {
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
      },
      async saveMedias(id) {
        if (['production_house', 'agency'].includes(this.me.role)) {
          this.formValues?.company_showreel_image_file?.forEach(
            (image, idx) => {
              if (image && !image.id) {
                this.uploadFile(image, 6, idx, id);
              }
            }
          );
          if (
            this.formValues?.company_showreel_video_file &&
            !this.formValues?.company_showreel_video_file?.id
          ) {
            let values = this.formValues?.company_showreel_video_file;
            this.$pushVideoToCompress({
              file: [values],
              extra: {
                profileId: this.userInfo?.profile?.profile_id,
                mediaId: 5
              }
            });
          }
          if (
            this.formValues?.company_logo_file &&
            !this.formValues?.company_logo_file?.id
          ) {
            this.uploadFile(this.formValues?.company_logo_file, 4, 0, id);
          }
        } else {
          const singleFileKey = ['cv', 'headshot'];

          this.freelancerMedia.forEach((m) => {
            const mediaId = this.mediaCollections.indexOf(
              `profiles.${m.mediaCollectionsName}`
            );
            if (
              singleFileKey.includes(m.mediaCollectionsName) &&
              !this.formValues[m.key]?.id
            ) {
              if (this.formValues[m.key]) {
                this.uploadFile(this.formValues[m.key], mediaId, -1, id);
              }
            }
            if (!singleFileKey.includes(m.mediaCollectionsName)) {
              this.formValues[m.key]?.forEach((item, idx) => {
                if (item && !item.id) {
                  this.uploadFile(item, mediaId, idx, id);
                }
              });
            }
          });
        }
      },
      async uploadFile(img, mediaId, index, id) {
        let data = {
          file: img.file
        };
        if (id == 6) {
          data.seq = index + 1;
        }
        await PROFILE.uploadFile(id, data, mediaId);
      },
      navigateToDisplayInfo() {
        this.openAppDialogInfo(
          'success',
          `Profile ${this.isCreate ? 'created' : 'updated'}`,
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
                this.$router.push({
                  name: ROUTE_NAME.DISPLAY_INFORMATIONS,
                  params: {
                    navigateToEditProfile: true
                  }
                });
              }
            }
          ]
        );
      },
      goLive() {
        this.currentStep = -1;
        this.isLive = true;
      }
    },

    async mounted() {
      await this.getMe();
      if (!this.me.profile) {
        this.isCreate = true;
        let extra = {};
        const findVal = (key, val) => {
          return this.constantsData[key].find((x) => x.description === val)
            ?.value;
        };
        switch (this.me.role) {
          case 'agency':
            extra = {
              description: findVal('agency_description', this.me.description),
              talents_no: findVal('talents_no', this.me.talents_no)
            };
            break;
          case 'production_house':
            extra = {
              description: findVal(
                'production_house_description',
                this.me.description
              )
            };
            break;
          case 'freelancer':
          case 'talent':
            extra = {
              gender: findVal('gender', this.me.gender)
            };
            break;
        }
        this.formValues = {
          ...this.me,
          ...extra
        };
      }
      if (this.$route.params?.goLive) this.goLive();
      else if (this.$route.params?.lastStep) this.currentStep = 3;
      else this.currentStep += 1;
    }
  };
</script>
<style lang="scss"></style>

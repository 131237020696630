import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-5 text-left md-font"},[_vm._v(" "+_vm._s(_vm.agencyRole ? 'Personal Info' : 'Business Basic Info')+" ")]),_c(VForm,{ref:"form",staticClass:"businessInfoForm w-100",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"w-100",attrs:{"no-gutters":""}},_vm._l((_vm.businessInfoFormFields),function(form,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"px-2"},[(form.field !== 'other')?_c('AppFormField',{attrs:{"name":("form_" + (form.key)),"label":_vm.$t(("label." + (form.label))),"rules":form.rules,"type":form.formType,"items":form.options,"multiple":form.multiple,"disabled":form.disabled,"itemColor":"background","color":form.color || 'surface',"outlined":"","dark":""},on:{"input":_vm.input},model:{value:(_vm.formValues[form.key]),callback:function ($$v) {_vm.$set(_vm.formValues, form.key, $$v)},expression:"formValues[form.key]"}}):_vm._e(),(
              form.field == 'other' &&
              form.key == 'description_other' &&
              _vm.isOtherDescription
            )?_c('AppFormField',{attrs:{"name":("form_" + (form.key)),"label":_vm.$t(("label." + (form.label))),"rules":form.rules,"type":form.formType,"itemColor":"background","color":form.color || 'surface',"dark":""},on:{"input":_vm.input},model:{value:(_vm.formValues[form.key]),callback:function ($$v) {_vm.$set(_vm.formValues, form.key, $$v)},expression:"formValues[form.key]"}}):_vm._e(),(form.field === 'other' && form.key === 'mobile')?_c('vue-tel-input-vuetify',{staticClass:"telInput",attrs:{"preferredCountries":['SG', 'MY'],"defaultCountry":_vm.$get(_vm.formValues, 'mobileDialCodeCountry.abbr', 'MY'),"label":_vm.$t('label.mobileNo'),"mode":"","required":"","outlined":"","dark":"","input":"","disabled":""},on:{"input":_vm.onNumberChange,"country-changed":_vm.updateCountry},model:{value:(_vm.formValues.mobile),callback:function ($$v) {_vm.$set(_vm.formValues, "mobile", $$v)},expression:"formValues.mobile"}}):_vm._e()],1)])}),1)],1),_c(VRow,{staticClass:"text-center mt-12 pb-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VBtn,{staticClass:"btn1 mb-3",attrs:{"disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Save & Next ")])],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"underlineBtn mb-3",on:{"click":function($event){return _vm.$emit('doItLater')}}},[_vm._v(" Do it later ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
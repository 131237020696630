<template>
  <div>
    <div class="mb-5 text-left md-font">Business Show Case</div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="companyProfileForm w-100"
    >
      <v-row no-gutters justify="center" class="w-100">
        <v-col cols="12" md="12" class="mb-10">
          <CustomInput
            type="avatar"
            :rules="[]"
            v-model="formValues.company_logo_file"
            label="Company Logo*"
            name="company_logo_file"
            :config="{
              accept: 'image/*'
            }"
            :showFileName="false"
            clearMediaConfirmation
            @clearFile="clearFile"
            @input="input"
          />
        </v-col>
        <v-col cols="12" md="12">
          <div class="text-left mb-5">Company Info</div>
          <div>
            <CustomInput
              name="company_write_up"
              type="textarea"
              v-model="formValues.company_write_up"
              label="Company Write Up"
              :rules="[]"
              dark
              outlined
              @input="input"
            />
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-4">
          <div class="text-left mb-5 font-weight-bold">
            Upload videos and photos to increase your social engagement and
            being noticed.
          </div>
          <div>
            <CustomInput
              class="text-left"
              name="company_showreel_video_file"
              :rules="[]"
              type="file"
              :config="{
                accept: 'video/*'
              }"
              v-model="formValues.company_showreel_video_file"
              label="Video (Optional)"
              dark
              outlined
              clearMediaConfirmation
              @clearFile="clearFile"
              @input="checkFileSize($event, 'company_showreel_video_file')"
            />
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <div class="text-left">Photo</div>
          <div class="d-flex" style="overflow: auto">
            <div
              v-for="(photo, index) in formValues.company_showreel_image_file"
              :key="index"
              class="me-2"
            >
              <CustomInput
                v-if="photo"
                name="company_showreel_image_file"
                class="text-left"
                :rules="[]"
                type="file"
                :config="{
                  accept: 'image/*'
                }"
                v-model="formValues.company_showreel_image_file[index]"
                :showFileName="false"
                clearMediaConfirmation
                @clearFile="
                  clearGalleryFile('company_showreel_image_file', photo, index)
                "
                dark
                outlined
                @input="input"
              />
            </div>
            <div v-if="formValues.company_showreel_image_file.length < 5">
              <CustomInput
                class="text-left"
                name="company_showreel_image_file"
                :rules="[]"
                type="file"
                :config="{
                  accept: 'image/*'
                }"
                v-model="
                  formValues.company_showreel_image_file[
                    formValues.company_showreel_image_file.length
                  ]
                "
                dark
                outlined
                @input="input"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="my-10">
          <CustomInput
            type="textarea"
            name="list_projects"
            :rules="[]"
            v-model="formValues.list_projects"
            label="Notable Project"
            dark
            outlined
            @input="input"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters class="text-center mt-12 pb-2">
      <v-col cols="12" md="12">
        <v-btn class="btn1 mb-3" @click="save" :disabled="!valid">
          Save & Next
        </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <div class="underlineBtn mb-3" @click="$emit('doItLater')">
          Do it later
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { formValidationHelper } from '@/utils';
  import { PROFILE } from '@/api';
  import CustomInput from '@/components/inputs/custom-input.vue';
  import { AUTH_ME } from '@/store/auth.module';
  import uploadMediaMixin from '@/mixin/upload-media.mixin';
  import { bus } from '@/main.js';

  export default {
    name: 'CompanyProfile',
    mixins: [uploadMediaMixin],
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    components: {
      CustomInput
    },
    data: () => ({
      formValues: {
        company_logo_file: null,
        company_showreel_image_file: [],
        company_showreel_video_file: {},
        company_write_up: null,
        list_projects: null
      },
      valid: false,
      formValidation: formValidationHelper
    }),
    methods: {
      fileSelect() {
        this.$refs.profilePicInput.click();
      },
      async clearGalleryFile(type, data, index) {
        // this.formValues[type].splice(index, 1);
        await this.clearFile(data);
        this.formValues[type].splice(index, 1);
        if (data.id) {
          let imgArray = [];
          this.formValues[type].forEach((img, index) => {
            if (img?.id) {
              imgArray.push([img.id, index + 1]);
            }
          });
          await PROFILE.sortFile(
            this.userInfo?.profile?.profile_id,
            {
              images: {
                ...Object.fromEntries(imgArray)
              }
            },
            6
          );
        }
      },
      async clearFile(data) {
        if (data?.id) {
          this.$startLoading();
          try {
            await PROFILE.removeFile(data.id);
            await this.$store.dispatch(AUTH_ME);
            this.$closeModal();
          } catch (e) {
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
      },
      async save() {
        this.$startLoading();
        try {
          if (!this.isCreate) {
            await PROFILE.updateCompanyProfile(this.formValues);
            await Promise.all(
              this.formValues?.company_showreel_image_file?.map(
                async (image, idx) => {
                  if (image && !image.id) {
                    await this.uploadFile(image, 6, idx);
                  }
                }
              )
            );
            if (
              this.formValues?.company_showreel_video_file &&
              !this.formValues?.company_showreel_video_file?.id
            ) {
              let values = this.formValues?.company_showreel_video_file;
              this.$pushVideoToCompress({
                file: [values],
                extra: {
                  profileId: this.userInfo?.profile?.profile_id,
                  mediaId: 5
                }
              });
              // await this.processValue(
              //   [values], 
              //   {
              //     profileId: this.userInfo?.profile?.profile_id,
              //     mediaId: 5
              //   }
              // )
            }
            if (
              this.formValues?.company_logo_file &&
              !this.formValues?.company_logo_file?.id
            ) {
              await this.uploadFile(this.formValues?.company_logo_file, 4, 0);
            }
          }

          this.$closeModal();
          this.next();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      async uploadFile(img, id, index) {
        let data = {
          file: img.file
        };
        if (id == 6) {
          data.seq = index + 1;
        }
        await PROFILE.uploadFile(
          this.userInfo?.profile?.profile_id,
          data,
          id
        ).catch((e) => {
          this.openAppDialogInfo('error', e.error_message || 'Request Failed', '', [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]);
        });
      },
      checkFileSize(e, name) {
        if (e?.file?.size > 524288000) {
          if (name == 'company_showreel_image_file')
            this.formValues[name][this.formValues[name].length - 1] = null;
          else this.formValues[name] = null;
          this.openAppDialogInfo(
            'error',
            'File size must not be greater than 500MB.',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.input();
      },
      next() {
        this.$emit('next', this.formValues);
      },
      input() {
        this.$emit('input', this.formValues);
      }
    },
    mounted() {
      this.formValues = {
        ...this.userInfo.profile,
        company_showreel_image_file:
          this.userInfo.profile?.company_showreel_image_file || [],
        company_showreel_video_file:
          this.userInfo.profile?.company_showreel_video_file || null
      };
    },
    created() {
      bus.$on('refreshProfile', async () => {
        await this.getMe();
      });
    }
  };
  
</script>

<style></style>
